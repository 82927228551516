
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as common from '@/operations/common';
import InfiniteLoading from 'vue-infinite-loading';
import AccountSign from '@/types/AccountSign';

import ModalAuctionCreation from '../util/ModalAuctionCreation.vue';
import ModalTokenPreview from '../previewModal/ModalTokenPreview.vue';
import ModalSafeTransfer from '../util/ModalSafeTransfer.vue';

import BaseTokenBox from '../BaseTokenBox.vue';
import Env from '@/types/Env';
import TokenProperty from '@/types/TokenProperty';

@Component({
  components: { InfiniteLoading, ModalAuctionCreation, ModalSafeTransfer, ModalTokenPreview, BaseTokenBox },
})
export default class collectionGalleryView extends Vue {
  @Prop() nftBalance;
  @Prop() smartcontractAddress;
  @Prop() index;

  public isFiltering = 0;
  public loadingNumber = 20;
  // public addressTokenIdList: TokenProperty[] | undefined = [];

  public distance = -Infinity;

  get addressTokenIdList(): TokenProperty[] | undefined {
    return this.index;
  }

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get accountView() {
    return common.getAddressInfo(this.$connex, this.$store.state, this.$route.params.ownerAddress);
  }

  get env(): Env {
    return this.$store.getters.env;
  }

  isOnAuction(tokenId: number, smartcontractAddress: string): boolean {
    return common.isOnAuction(this.$store.state, tokenId, smartcontractAddress)[0].isOnAuction;
  }

  public manualLoad() {
    this.distance = 100;
    this.loadingNumber = this.loadingNumber + 15;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async infiniteHandler($state): Promise<void> {
    if (this.loadingNumber >= this.nftBalance) {
      $state.loaded();
      $state.complete();
    } else {
      this.loadingNumber = this.loadingNumber + 5;
      $state.loaded();
    }
  }

  @Watch('index', { immediate: true }) onItemChanged(): void {
    // this.addressTokenIdList = this.index;
  }
  @Watch('smartcontractAddress', { immediate: true }) onSmartcontractAddressChanged(): void {
    // this.addressTokenIdList = this.index;
  }
}
