






































import { Component, Vue, Watch } from 'vue-property-decorator';
import { picasso } from '@vechain/picasso';
import { bus } from '../main';

import WalletBox from '../components/util/WalletBox.vue';
import AddressCardGallery from '../components/cardsRender/addressCardGallery.vue';
import AddressCardOccurence from '../components/cardsRender/addressUniqueCard.vue';
import CollectionGalleryView from '../components/cardsRender/collectionGalleryView.vue';
import * as readVIP181Core from '../operations/readVIP181Core';
import * as common from '../operations/common';
import AccountSign from '@/types/AccountSign';
import TokenProperty from '@/types/TokenProperty';

import * as blockchainMethod from '@/operations/blockchainMethod';
import AddressInfo from '@/types/AddressInfo';
import { forEach } from 'cypress/types/lodash';

@Component({
  components: { WalletBox, AddressCardGallery, AddressCardOccurence, CollectionGalleryView },
})
export default class Owner extends Vue {
  public isMounted = false;
  public svg = {};

  public loadingNumber = 10;
  public loaded = false;

  public accountView!: AddressInfo;

  public tokenOfOwnerByIndex = [] as any;
  public myCollectionData: TokenProperty[] = [];
  public myCreationsData: TokenProperty[] = [];
  public onSaleData: TokenProperty[] = [];

  public accountInfo = [] as any;
  public accountPicture = '';
  public accountRegister = false;

  public isFiltering = 0;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get env() {
    return this.$store.getters.env;
  }

  get marketplaceLocal() {
    return this.$store.getters.marketplaceSortPriceLowHigh;
  }

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  public async getAccountView(): Promise<void> {
    this.accountView = await common.getAddressInfo(this.$connex, this.$store.state, this.$route.params.ownerAddress);
    this.accountInfo = await blockchainMethod.getAccountInfo(
      this.$connex,
      this.$ENV.WOV_ACCOUNT_CONTRACT,
      this.$route.params.ownerAddress
    );
    this.accountPicture = 'https://worldofv-marketplace.mypinata.cloud/ipfs/' + this.accountInfo[2];
    if (parseInt(this.accountInfo[0]) > 0) {
      this.accountRegister = true;
    } else {
      this.accountRegister = true;
    }
  }

  public async refreshMyCollection(wovCommunityBalance: number): Promise<void> {
    this.isFiltering++;


    if (wovCommunityBalance > 0) {
      let chunks = this.chunkBy(wovCommunityBalance, 50);

      let start = 0;
      let end = 0;

      for (let c = 0; c < chunks.length; c++) {
        end = end + chunks[c];

        const exp = await blockchainMethod.getMyCollection(
          this.$connex,
          this.$ENV.WOV_COMMUNITY_CARD_NFT,
          this.$route.params.ownerAddress,
          start,
          end
        );

        await exp
          .gas(200000) // Set maximum gas
          .caller(this.$route.params.ownerAddress) // Set caller
          .execute()
          .then((outputs) => {
            start = start + parseInt(chunks[c]);
            outputs.forEach((element) => {
              // for now we keep some static data because we have a hatching of two weeks
              let token = {
                tokenId: parseInt(element.data, 16),
                tokenName: 'VeHashes',
                tokenCreator: '0xE52fc0e8538e4eCb8718Aa0dfEcb9b6D066746d6',
                ipfsHash: 'https://vehashes.s3.eu-central-1.amazonaws.com/metadata/' + parseInt(element.data, 16) + '.json',
                fileHash: 'https://vehashes.s3.eu-central-1.amazonaws.com/assets/' + parseInt(element.data, 16)  + '.png',
                fileType: 'image/jpg',
                collectionName: 'VeHashes 2021',
                description: 'VeHashes is a PFP collection of 10 000 unique NFTs featuring a fun, creative visual style. The collection celebrates the VeFam community by giving life to our beloved V.',
                creatorCommission: 0,
                curatedArt: false,
              };
              this.myCollectionData.push(token);
            });
          });
      }
    }
  }

  public async refreshWOV(): Promise<void> {
    this.$store.dispatch('refreshAccountWoV');
  }

  @Watch('$route.params.ownerAddress')
  async onPropertyChanged(): Promise<void> {
    // Do stuff with the watcher here.
    this.isMounted = false;
    this.refreshAccountWallet();
    this.getAccountView();

    bus.$emit('showToast', true);
    setTimeout(() => {
      this.isMounted = true;
    }, 3000);
  }

  public async refreshAccountWallet(): Promise<void> {
    this.myCollectionData.length = 0;
    const account = await common.getAddressInfo(this.$connex, this.$store.state, this.$route.params.ownerAddress);

    this.refreshMyCollection(account.wovBalanceOfOwner);
    this.getAccountView();
  }

  public async mounted(): Promise<void> {
    this.svg = {
      float: 'left',
      width: '70px',
      height: '70px',
      background: "no-repeat url('data:image/svg+xml;utf8," + picasso(this.$route.params.ownerAddress) + "')",
    };
    this.myCollectionData.length = 0;
    this.tokenOfOwnerByIndex.length = 0;
    this.refreshAccountWallet();
    setTimeout(() => {
      this.isMounted = true;
      this.isFiltering++;
    }, 1000);
  }

  public async created() {
    console.log("toto")
    window.location.href = "https://marketplace.worldofv.art/profile/" + this.accountSignCompute.address;
    bus.$on('refreshApproval', () => {
      this.$store.dispatch('refreshAccountApproval');
    });
  }

  private chunkBy(number, n) {
    var chunks = Array(Math.floor(number / n)).fill(n);
    var remainder = number % n;

    if (remainder > 0) {
      chunks.push(remainder);
    }
    return chunks;
  }
}
