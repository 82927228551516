





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as common from '@/operations/common';
import AccountSign from '@/types/AccountSign';

import ModalAuctionCreation from '../util/ModalAuctionCreation.vue';
import ModalSafeTransfer from '../util/ModalSafeTransfer.vue';
import Env from '@/types/Env';

@Component({
  components: { ModalAuctionCreation, ModalSafeTransfer },
})
export default class addressCardGallery extends Vue {
  @Prop() collectionFilter;
  @Prop() nftBalance;
  @Prop() smartcontractAddress;
  @Prop() index;
  @Prop() loadingNumber;

  public isFiltering;
  public tokenNameArray = [] as any;

  public occurrence = [] as any;

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  name(tokenId: number) {
    if (this.tokenNameArray.find((index) => index.tokenId === tokenId)) {
      return this.tokenNameArray.find((index) => index.tokenId === tokenId).name;
    } else {
      return '';
    }
  }

  indexProcessed() {
    if (this.index.length === this.nftBalance && this.nftBalance > 0) {
      switch (this.collectionFilter) {
        case 0:
          return [];
        default:
          return [];
      }
    } else {
      return [];
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get accountView() {
    return common.getAddressInfo(this.$connex, this.$store.state, this.$route.params.ownerAddress);
  }


  get env(): Env {
    return this.$store.getters.env;
  }

  isOnAuction(tokenId: number, smartcontractAddress: string): boolean {
    return common.isOnAuction(this.$store.state, tokenId, smartcontractAddress)[0].isOnAuction;
  }


  // @Watch('index', { immediate: true }) onItemChanged(): void {
  // }
  // @Watch('smartcontractAddress', { immediate: true }) onSmartcontractAddressChanged(): void {
  // }
}
