





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import * as common from '../../operations/common';

import modalAuctionCreation from '../util/ModalAuctionCreation.vue';
import modalSafeTransfer from '../util/ModalSafeTransfer.vue';

@Component({
  components: { modalAuctionCreation, modalSafeTransfer },
})
export default class addressCardOccurence extends Vue {
  @Prop() title;
  @Prop() nftBalance;
  @Prop() smartcontractAddress;
  @Prop() index;
  @Prop() loadingNumber;

  public isFiltering = 0;
  public occurrence;
  public occurenceButton = false;
  public showOccurence = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get accountSignCompute(): any {
    return this.$store.getters.account;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get accountView() {
    return common.getAddressInfo(this.$connex, this.$store.state, this.$route.params.ownerAddress);
  }


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get env() {
    return this.$store.getters.env;
  }

  public showHide() {
    if (this.showOccurence) {
      this.showOccurence = false;
    } else {
      this.showOccurence = true;
    }
  }

  public findOcc(arr, key) {
    let arr2 = [{ countryName: '', occurrence: 0 }];
    arr2.length = 0;

    arr.forEach((x) => {
      // Checking if there is any object in arr2
      // which contains the key value
      if (
        arr2.some((val) => {
          return val[key] == x[key];
        })
      ) {
        // If yes! then increase the occurrence by 1
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k['occurrence']++;
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the occurrence to 1
        // let a = {} as any;
        let a = {
          countryName: '',
          occurrence: 0,
        };
        a['countryName'] = x[key];
        a['occurrence'] = 1;

        arr2.push(a);

        this.isFiltering++;
      }
    });
    return arr2;
  }

  @Watch('index', { immediate: true }) onItemChanged(): void {
    if (this.index.length === this.nftBalance && this.nftBalance > 0) {
      this.occurrence = this.findOcc(this.index, 'countryName');
      this.occurrence = this.occurrence.sort((a, b) => (a.occurrence < b.occurrence ? 1 : -1));
      this.occurenceButton = true;
    }
  }

}
